/**
 * Helper function to handle unexpected cases and throw if reached.
 *
 * @param error The error to throw
 */
export function throwError(error: Error | string): never {
  throw error instanceof Error ? error : new Error(error);
}

/**
 * Helper function to alert compiler to unhandled cases, or throw error if somehow reached.
 *
 * @param value The value which is not expected.
 */
export function unhandledCase(value: never): never {
  throw new Error(`Unhandled case for '${value}'`);
}

export class ErrorWithData extends Error {
  private errorData?: Record<string, any>;
  constructor(message?: string, errorData?: Record<string, any>) {
    super(message);
    this.errorData = errorData;
  }
}
