import { AddIcon } from "@chakra-ui/icons";
import { Button, VStack, useToast } from "@chakra-ui/react";
import { Operation } from "@syla/shared/types/requests/BulkEditRequest";
import { uniqueId } from "lodash";
import React, { useState, useContext, useCallback, useMemo } from "react";
import { filterToFilterParams } from "../../../../../api/order/getGroups";
import { ModalContext } from "../../../../../contexts/ModalContext";
import { useReportError } from "../../../../../helpers/useReportError";
import { useCurrentAccountStore } from "../../../../../store/currentAccountStore";
import { useMutationBulkEdit } from "../../../../../store/useMutationBulkEdit";
import { OrderFilterSetting } from "../../../../../types/order/orderQuery";
import { ButtonVariant } from "../../../../atoms/ButtonVariant";
import { StdVStack } from "../../../../atoms/Containers";
import { ModalContent } from "../../../../atoms/ModalContent";
import {
  OperationEntry,
  AdjustTimeOperationEntry,
  ChangeWalletOperationEntry,
  SwitchAssetOperationEntry,
  SwitchLedgerOperationEntry,
  operationDefs,
} from "./common";
import {
  AssetBulkEdit,
  validateAssetOperation,
} from "./operations/AssetBulkEdit";
import {
  LedgerBulkEdit,
  validateLedgerOperation,
} from "./operations/LedgerBulkEdit";
import { TimeBulkEdit, validateTimeOperation } from "./operations/TimeBulkEdit";
import {
  WalletBulkEdit,
  validateWalletOperation,
} from "./operations/WalletBulkEdit";

export function BulkEdit({
  filters,
  selectedCount,
  operationType,
  selectedIds,
  clearSelected,
}: {
  filters: OrderFilterSetting;
  selectedCount?: number;
  operationType?: Operation["type"];
  selectedIds: string[];
  clearSelected: () => void;
}) {
  const toast = useToast();
  const { closeModal } = useContext(ModalContext);
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  // propopulate from selected filters
  const [operations, setOperations] = useState<OperationEntry[]>([]);

  const [editMode, setEditMode] = useState<OperationEntry["type"] | undefined>(
    operationType,
  );

  const operationsValid = useMemo(() => {
    return operations
      .map((op) => {
        switch (op.type) {
          case "ledger":
            return validateLedgerOperation(op);
          case "asset":
            return validateAssetOperation(op);
          case "wallet":
            return validateWalletOperation(op);
          case "time":
            return validateTimeOperation(op);
        }
      })
      .every(Boolean);
  }, [operations]);

  const reportError = useReportError();
  const { mutateAsync: bulkEdit } = useMutationBulkEdit(accountId);

  const [isRunning, setIsRunning] = useState(false);
  const onSubmit = useCallback(async () => {
    try {
      setIsRunning(true);

      const { updatedCount } = await bulkEdit({
        filters: filterToFilterParams(filters),
        groupIds: selectedIds,
        operations: operations as Operation[],
      });
      toast({
        status: "info",
        position: "bottom",
        title: `${updatedCount.toLocaleString()} transactions updated`,
      });
      closeModal();
      clearSelected();
    } catch (error) {
      reportError(error);
    } finally {
      setIsRunning(false);
    }
  }, [
    bulkEdit,
    clearSelected,
    closeModal,
    filters,
    operations,
    reportError,
    selectedIds,
    toast,
  ]);

  return (
    <StdVStack gridGap={4} overflowX="hidden">
      <ModalContent>
        {/* Pick operation */}
        {!editMode && (
          <VStack width="20rem">
            {Object.entries(operationDefs).map(([type, { label }]) => (
              <ButtonVariant
                key={type}
                content={label}
                color="white"
                outlineType="outlineBlack"
                spam="spam"
                onClick={() => {
                  setEditMode(type as OperationEntry["type"]);
                }}
              />
            ))}
          </VStack>
        )}

        {/* Edit operations */}
        {editMode &&
          (editMode == "ledger" ?
            <LedgerBulkEdit
              operations={operations as SwitchLedgerOperationEntry[]}
              setOperations={setOperations as any}
              selectedIds={selectedIds}
              filters={filters}
            />
          : editMode == "asset" ?
            <AssetBulkEdit
              operations={operations as SwitchAssetOperationEntry[]}
              setOperations={setOperations as any}
              selectedIds={selectedIds}
              filters={filters}
            />
          : editMode == "wallet" ?
            <WalletBulkEdit
              operations={operations as ChangeWalletOperationEntry[]}
              setOperations={setOperations as any}
              selectedIds={selectedIds}
              filters={filters}
            />
          : editMode == "time" ?
            <TimeBulkEdit
              operations={operations as AdjustTimeOperationEntry[]}
              setOperations={setOperations as any}
            />
          : null)}
      </ModalContent>

      {/* Add operation */}
      {editMode &&
        (operationDefs[editMode].maxAmount == null ||
          operations.filter((o) => o.type == editMode).length <
            operationDefs[editMode].maxAmount!) && (
          <Button
            leftIcon={<AddIcon />}
            variant="solid"
            onClick={() =>
              setOperations((values) => [
                ...values,
                { type: editMode, id: uniqueId() },
              ])
            }
          >
            Add
          </Button>
        )}

      {/* Action button  */}
      {editMode && (
        <ButtonVariant
          content={`Update ${selectedCount?.toLocaleString()} transactions`}
          color="red"
          spam="spam"
          isDisabled={!operations.length || !operationsValid}
          onClick={() => onSubmit()}
          isLoading={isRunning}
        />
      )}
      {editMode && (
        <ButtonVariant
          content="Back"
          color="white"
          outlineType="outlineBlack"
          spam="spam"
          onClick={() => setEditMode(undefined)}
          isDisabled={isRunning}
        />
      )}
    </StdVStack>
  );
}
