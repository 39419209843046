import { parseOrderResponseItem } from "@syla/shared/types/helpers/parseOrderResponseItem";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { withoutNullable } from "@syla/shared/types/helpers/withoutNullable";
import {
  GetGroupsFilter,
  GetGroupsRequest,
} from "@syla/shared/types/requests/GetGroupsRequest";

import { GetGroupsResponse } from "@syla/shared/types/responses/GetGroupsResponse";
import axios from "axios";
import { addDays, addMinutes } from "date-fns";
import { RangeValue } from "rc-picker/lib/interface";
import {
  OrderFilterSetting,
  OrderSortOption,
} from "../../types/order/orderQuery";

export interface GetGroupsProps {
  accountId: string;
  pageParam: number;
  limit: number;
  sort: OrderSortOption | undefined;
  filter: OrderFilterSetting;
}

export const getGroups = async ({
  accountId,
  pageParam = 0,
  limit = 8,
  sort,
  filter,
}: GetGroupsProps): Promise<GetGroupsResponse<string>> => {
  const params: GetGroupsRequest<string> = {
    limit,
    start: pageParam * limit,
    sort: (sort as any) || undefined, // sort is empty string ("") when an option is unselected in the sort by component
    ...filterToFilterParams(filter),
  };
  const { data }: { data: TransportType<GetGroupsResponse<string>> } =
    await axios.get(`/accounts/${accountId}/orders`, {
      params,
    });

  return {
    ...data,
    data: withoutNullable(
      data.data.map((data) => parseOrderResponseItem(data)),
    ),
  };
};

export function filterToFilterParams({
  asset,
  dataSource,
  date,
  ledgerType,
  searchQuery,
  status,
  transactionType,
  imports,
}: OrderFilterSetting): GetGroupsFilter<string> {
  return {
    asset: asset.length ? asset : undefined,
    dataSource: dataSource.length ? dataSource : undefined,
    transactionType: transactionType.length ? transactionType : undefined,
    ledgerType: ledgerType.length ? ledgerType : undefined,
    datePeriod:
      date?.financialYear ?
        [date.financialYear.startDate, date.financialYear.nextYearStartDate]
      : date?.range ? filterDateRangeToSelectedDateRange(date.range)
      : undefined,
    searchQuery: searchQuery || undefined, // don't pass in the search query if it is an empty string
    status: status.length ? status : undefined,
    imports,
  };
}

/** Convert datepicker range to api range, with exclusive end date */
export const filterDateRangeToSelectedDateRange = (
  dateRange: RangeValue<Date>,
): Date[] | undefined =>
  dateRange && dateRange[0] && dateRange[1] ?
    [
      shiftTimeToBrisbane(dateRange[0]),
      shiftTimeToBrisbane(addDays(dateRange[1], 1)),
    ]
  : undefined;

const shiftTimeToBrisbane = (date: Date): Date => addMinutes(date, -600); // convert time back to brisbane
